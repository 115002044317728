import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Helmet } from "react-helmet";

import { motion } from "framer-motion"
import FadeIn from 'react-fade-in';

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import AboutHeader from "../components/sections/about/aboutheader"
import AboutLander from "../components/sections/about/aboutlander"
import Team from "../components/sections/about/team"
import Footer from "../components/sections/footer"

import { SectionDivider, HalfColumn, TwoColumn } from "../components/global"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>About Dili – Who We Are, What We Do & How We Got Here</title>
      <meta name="description" content="Dili is a leading Fintech lending company offering high ticket & long tenure loans at zero cost EMI for the personal & professional growth of progressive India." />
    </Helmet>
    <Navigation></Navigation>
    <FadeIn>
    <HeaderContainer>
        <Headline>
           Page Not Found - Dili
        </Headline>
    </HeaderContainer>   
    </FadeIn>
    <Footer></Footer>
  </Layout>
)

export default NotFoundPage

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 180px;
    padding-bottom: 80px;
    text-align: center;
`

const Headline = styled.div`
    color: #142630;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    line-height: 1.2;

    @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 48px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 48px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 36px;
        width: 80%
    }
`

const SubHeadline = styled(motion.div)`
    color: #465F6D;
    font-size: 36px;
    letter-spacing: -0.02em;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-top: 24px;

    @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 48px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 48px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 36px;
        width: 80%
    }
`

const HeaderText = styled.div`
    color: #465F6D;
    font-size: 18px;
    margin-top: 32px;
    line-height: 1.5;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${props => props.theme.screen.md}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 80%
    }
`

const HeaderButton = styled(motion.div)`
    font-weight: 400;
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    color: white;
    letter-spacing: -0.02em;
    display: block;
    margin-top: 40px;
    cursor: pointer;
    white-space: nowrap;
    background: #7A8C96;
    border-radius: 32px;
    padding: 16px 32px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    outline: 0px;
    &:hover {
        box-shadow: #7A8C96 0px 2px 10px 0px;
    }
    @media (max-width: ${props => props.theme.screen.md}) {
    }
    @media (max-width: ${props => props.theme.screen.sm}) {
    }
`

const HeaderImage = styled.img`
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    border-radius: 20px;

    @media (max-width: ${props => props.theme.screen.md}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 80%
    }
`

const FormSection = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
`

export const BlueText = styled.div`
    color: #0084DD;
    font-weight: bold;
    cursor: pointer;
`

export const RMText = styled.div`
    line-height: 1.5;
`
